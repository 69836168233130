export interface IUnitType {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
}

export class UnitType {
  constructor(public id: string, public createdAt: Date, public updatedAt: Date, public name: string) {}

  public static Build(unitTypes: IUnitType): UnitType {
    return new this(unitTypes.id, new Date(unitTypes.createdAt), new Date(unitTypes.updatedAt), unitTypes.name);
  }
}
