export enum Role {
  NONE = 'none',
  TEACHER = 'teacher',
  MANAGER = 'manager',
  //ruoli V3
  STUDENT = 'student',
  ADMIN = 'admin',
  COORDINATOR = 'coordinator',
  EDITOR = 'editor',
  COACH = 'coach',
  TUTOR = 'tutor',
  ALL = 'all',
}

export const ROLE_OPTIONS = Object.values(Role).sort();

export const STAFF_ROLE_OPTIONS = ROLE_OPTIONS.filter((role) => {
  const notStaffRoles = new Set([Role.NONE, Role.TEACHER, Role.MANAGER, Role.STUDENT, Role.ALL]);

  return !notStaffRoles.has(role);
});
