import { ContentType } from '../enums/content-type.enum';
import { ContentExample } from '../models/content/content-example.model';
import { ContentExercise, IContentExercise } from '../models/content/content-exercise.model';
import { ContentFile, IContentFile } from '../models/content/content-file.model';
import { ContentSurvey, IContentSurvey } from '../models/content/content-survey.model';
import { ContentTest, IContentTest } from '../models/content/content-test.model';
import { IContent } from '../models/content/content.model';
import { IContentExample } from './../models/content/content-example.model';
import { ContentLink, IContentLink } from './../models/content/content-link.model';
import { ContentVideo, IContentVideo } from './../models/content/content-video.model';

export class ContentFactory {
  public static createContent(content: IContent) {
    const { type } = content;

    switch (type) {
      case ContentType.EXAMPLE:
        return ContentExample.Build(content as IContentExample);
      case ContentType.FILE:
        return ContentFile.Build(content as IContentFile);
      case ContentType.LINK:
        return ContentLink.Build(content as IContentLink);
      case ContentType.EXERCISE:
        return ContentExercise.Build(content as IContentExercise);
      case ContentType.VIDEO:
        return ContentVideo.Build(content as IContentVideo);
      case ContentType.SURVEY:
        return ContentSurvey.Build(content as IContentSurvey);
      case ContentType.TEST:
        return ContentTest.Build(content as IContentTest);
      default:
        throw new Error('Invalid content type: ' + type);
    }
  }
}
