import { Injectable } from '@angular/core';
import { IConfirmActionPopupTexts } from '@libs/data/models/modal.model';
import { ConfirmActionPopupComponent } from '@libs/ui-kit/components/confirm-action-popup/confirm-action-popup.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRef!: NgbModalRef;

  constructor(private readonly modalService: NgbModal) {}

  public open(data: IConfirmActionPopupTexts): NgbModalRef {
    this.modalRef = this.modalService.open(ConfirmActionPopupComponent, { centered: true });
    this.modalRef.componentInstance.fromParent = data;

    return this.modalRef;
  }

  public openCustom(
    customComponent: unknown,
    data?: unknown,
    option: NgbModalOptions = { centered: true }
  ): NgbModalRef {
    this.modalRef = this.modalService.open(customComponent, option);
    this.modalRef.componentInstance.fromParent = data;

    return this.modalRef;
  }

  public openDelete(
    data: IConfirmActionPopupTexts = {
      title: 'Warning',
      description: 'CourseTypeDeleteMsg',
      confirm: 'Delete',
      back: 'Back',
      isNegativePrimaryBtn: false,
    }
  ): NgbModalRef {
    this.modalRef = this.modalService.open(ConfirmActionPopupComponent, { centered: true });
    this.modalRef.componentInstance.fromParent = data;

    return this.modalRef;
  }

  public openConfirm(
    data: IConfirmActionPopupTexts = {
      title: 'Warning',
      description: 'students.AreYouSureYouWantToSubmitExercise',
      confirm: 'Send',
      back: 'Back',
      isNegativePrimaryBtn: false,
    }
  ): NgbModalRef {
    this.modalRef = this.modalService.open(ConfirmActionPopupComponent, { centered: true });
    this.modalRef.componentInstance.fromParent = data;

    return this.modalRef;
  }

  public getResult(): Promise<boolean> {
    return this.modalRef.result;
  }
}
