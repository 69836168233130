type AccessibleIcon = Record<string, { src: string; alt: string }>;

export const Icon = {
  HOME_ICON: {
    src: 'assets/images/icons/home.svg',
    alt: 'alt.home-icon',
  },
  FORWARD_ARROW: {
    src: 'assets/images/icons/forward-arrow.svg',
    alt: 'alt.pagination-arrow-icon',
  },
  DOUBLE_ARROW: {
    src: 'assets/images/icons/double-arrow.svg',
    alt: 'alt.pagination-arrow-icon',
  },
  ARROW_UP: {
    src: 'assets/images/icons/arrow-up-grey.svg',
    alt: 'alt.move-up',
  },
  ARROW_DOWN: {
    src: 'assets/images/icons/arrow-down-grey.svg',
    alt: 'alt.move-down',
  },
  DRAG_DOTS: {
    src: 'assets/images/icons/drag-dots-grey.svg',
    alt: 'alt.drag-dots',
  },
  TRASH_ICON: {
    src: 'assets/images/icons/trash.svg',
    alt: 'alt.trash-icon',
  },
  TRASH_GREY_ICON: {
    src: 'assets/images/icons/units/trash-grey.svg',
    alt: 'alt.trash-icon',
  },
  ADD_ANSWER_ICON: {
    src: 'assets/images/icons/units/add-answer-icon.svg',
    alt: 'alt.add-answer-icon',
  },
  REMOVE_ANSWER_ICON: {
    src: 'assets/images/icons/units/remove-x-grey.svg',
    alt: 'alt.remove-answer-icon',
  },
  RED_TRASH_ICON: {
    src: 'assets/images/icons/trash-red.svg',
    alt: 'alt.trash_icon',
  },
  DELETE_ICON: {
    src: 'assets/images/icons/delete.svg',
    alt: 'alt.delete_icon',
  },
  EMPTY_CHECKBOX: {
    src: 'assets/images/icons/checkbox-empty.svg',
    alt: 'alt.checkbox-empty',
  },
  SELECT_ALL_CHECKBOX: {
    src: 'assets/images/icons/checkbox-all-selected.svg',
    alt: 'alt.all-selected',
  },
  DESELECT_CHECKBOX: {
    src: 'assets/images/icons/checkbox-deselect.svg',
    alt: 'alt.checkbox-partially',
  },
  COPY_ICON: {
    src: 'assets/images/icons/copy.svg',
    alt: 'alt.copy-text-icon',
  },
  ARROW_DOWN_SMALL_ICON: {
    src: 'assets/images/icons/arrow-down-small.svg',
    alt: 'alt.order-by-icon',
  },
  ARROW_UP_SMALL_ICON: {
    src: 'assets/images/icons/arrow-up-small.svg',
    alt: 'alt.order-by-icon',
  },
  PARTY_POPPER_BLUE: {
    src: 'assets/images/icons/party-popper-blue.svg',
    alt: 'alt.congratulations-icon',
  },
  EMPTY_STATE_HOMEPAGE_STUDENT: {
    src: 'assets/images/backgrounds/empty-state-homepage-student.svg',
    alt: 'alt.empty-state-homepage-student',
  },
  HOMEPAGE_STAFF: {
    src: 'assets/images/backgrounds/empty-state-homepage-student.svg',
    alt: 'alt.homepage-staff',
  },
  EXERCISE: {
    src: 'assets/images/icons/exercise.svg',
    alt: 'alt.exercise',
  },
  APPROVED_EXERCISE: {
    src: 'assets/images/icons/approved-exercise.svg',
    alt: 'alt.approved-exercise',
  },
  REJECTED_EXERCISE: {
    src: 'assets/images/icons/rejected-exercise.svg',
    alt: 'alt.rejected-exercise',
  },
  TO_REVIEW_EXERCISE: {
    src: 'assets/images/icons/to-review-exercise.svg',
    alt: 'alt.to-review-exercise',
  },
  VIDEO: {
    src: 'assets/images/icons/video-play.svg',
    alt: 'alt.video',
  },
  UNIT_COMPLETED: {
    src: 'assets/images/icons/complete-green.svg',
    alt: 'alt.unit-completed',
  },
  FILE: {
    src: 'assets/images/icons/file.svg',
    alt: 'alt.file',
  },
  NAVIGATION_ARROW: {
    src: 'assets/images/icons/arrow-navigation-blue.svg',
    alt: 'alt.arrow-navigation-forward',
  },
  TEST: {
    src: 'assets/images/icons/test.svg',
    alt: 'alt.test-in-progress',
  },
  COMPLETED: {
    src: 'assets/images/icons/complete-blue.svg',
    alt: 'alt.completed',
  },
  SURVEY: {
    src: 'assets/images/icons/survey.svg',
    alt: 'alt.survey',
  },
  BACK_TO_TOP: {
    src: 'assets/images/icons/back-to-top.svg',
    alt: 'alt.back-to-top',
  },
  TEST_ANSWER_ARROW: {
    src: 'assets/images/icons/arrow-next-answer.svg',
    alt: 'Modifica risposta',
  },
  ROBOT_AI: {
    src: 'assets/images/icons/robot-ai.svg',
    alt: 'alt.ai-correction',
  },
  ROBOT_AI_GREY: {
    src: 'assets/images/icons/robot-ai-grey.svg',
    alt: 'alt.grey-ai-robot',
  },
  PENCIL: {
    src: 'assets/images/icons/pencil.svg',
    alt: 'alt.correct-manually',
  },
} as AccessibleIcon;
