import { QuestionType } from '../../enums/question-type.enum';
import { IQuestion, Question } from './question.model';

export interface IQuestionOpen extends IQuestion {
  maxLength?: number;
  studentAnswer?: string;
}

export class QuestionOpen extends Question {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override description: string,
    public override question: string,
    public override type: QuestionType,
    public maxLength?: number,
    public studentAnswer?: string
  ) {
    super(id, createdAt, updatedAt, description, question, type);
  }

  public static Build(question: IQuestionOpen): QuestionOpen {
    return new this(
      question.id,
      new Date(question.createdAt),
      new Date(question.updatedAt),
      question.description,
      question.question,
      question.type,
      question.maxLength,
      question.studentAnswer
    );
  }
}
