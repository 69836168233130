import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SharedFacade } from '@libs/common/store/facades/shared.facade';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FirebaseFacade } from '../firebase/firebase.facade';
import { AuthService } from '../services/auth.service';
import { AuthFacade } from '../store/facades/auth.facade';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private readonly sharedFacade: SharedFacade,
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly firebaseFacade: FirebaseFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.sharedFacade.setRedirectUrl(state.url);

    return this.firebaseFacade.authState$.pipe(
      take(1),
      switchMap((firebaseUser) => {
        if (firebaseUser) {
          return this.authFacade.refreshCurrentUser().pipe(map(() => true));
        }

        this.authService.logout();

        return of(false);
      })
    );
  }
}
