import { StudentContentFactory } from '../factories/student-unit-content.factory';
import { IStudentUnitContent, StudentUnitContent } from './content/student-unit-content.model';

export interface IStudentUnitLesson {
  id: string;
  index: number;
  description: string;
  title: string;
  contents: IStudentUnitContent[];
}

export class StudentUnitLesson {
  constructor(
    public id: string,
    public index: number,
    public description: string,
    public title: string,
    public contents: StudentUnitContent[]
  ) {}

  public static Build(studentUnitLesson: IStudentUnitLesson): StudentUnitLesson {
    const contents = studentUnitLesson.contents?.map((content) => StudentContentFactory.createContent(content));

    return new this(
      studentUnitLesson.id,
      studentUnitLesson.index,
      studentUnitLesson.description,
      studentUnitLesson.title,
      contents
    );
  }
}
