export enum ClassAction {
  LIST = 'list',
  DETAIL = 'detail',
  CREATE = 'create',
  EDIT = 'edit',
  GRADUATE = 'graduate',
  MOVE_TO = 'move-to',
  EDIT_STUDENTS = 'edit-students',
  REMOVE_STUDENTS = 'remove-students',
  MOVE_STUDENTS = 'move-students',
  ADD_STUDENTS = 'add-students'
}
