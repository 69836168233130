import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 30, showDots = true): string {
    if (!showDots) {
      return value.substring(0, 3);
    } else {
      return value?.length >= limit ? `${value.substring(0, limit - 3)}...` : value;
    }
  }
}
