import { Language } from '@libs/data/features/user/models/language.model';
import { IBreadcrumb } from '@libs/ui-kit/components/breadcrumbs/models/breadcrumbs.model';
import { ITopBarLink } from '@libs/ui-kit/components/top-bar/models/top-bar-link.model';
import { createReducer, on } from '@ngrx/store';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { Nullable } from '../../types/nullable.type';
import * as SharedActions from '../actions/shared.actions';

export interface SnackbarConfig {
  message: string;
  title?: string;
  options: Partial<IndividualConfig>;
  type: 'info' | 'success' | 'error';
}

export interface SharedState {
  snackBarConfig: Nullable<SnackbarConfig>;
  showSnackBar: boolean;
  language: Language;
  topBarOtherLinks: ITopBarLink[];
  breadcrumbs: IBreadcrumb[];
  loading: boolean;
}

export const initialState: SharedState = {
  snackBarConfig: null,
  showSnackBar: false,
  language: Language.EN,
  topBarOtherLinks: [],
  breadcrumbs: [],
  loading: false,
};

export const sharedFeatureKey = 'shared';

export const sharedReducer = createReducer(
  initialState,

  on(SharedActions.showSnackBar, (state, { snackBarConfig }) => ({
    ...state,
    snackBarConfig,
    showSnackBar: true,
  })),

  on(SharedActions.clearSnackBar, (state) => ({
    ...state,
    snackBarConfig: null,
    showSnackBar: false,
  })),

  on(SharedActions.setLanguage, (state, { language }) => ({
    ...state,
    language,
  })),

  on(SharedActions.setTopBarOtherLinks, (state, { links }) => ({
    ...state,
    topBarOtherLinks: links,
  })),

  on(SharedActions.clearTopBarOtherLinks, (state) => ({
    ...state,
    topBarOtherLinks: initialState.topBarOtherLinks,
  })),

  on(SharedActions.setBreadCrumbs, (state, { breadcrumbs }) => ({
    ...state,
    breadcrumbs,
  })),

  on(SharedActions.clearBreadCrumbs, (state) => ({
    ...state,
    breadcrumbs: initialState.breadcrumbs,
  })),

  on(SharedActions.setGlobalLoader, (state, { loading }) => ({
    ...state,
    loading,
  }))
);
