import { ISessionTimeTrackingModel } from '@libs/data/features/student/models/session-time-tracking.model';
import { Language } from '@libs/data/features/user/models/language.model';
import { IBreadcrumb } from '@libs/ui-kit/components/breadcrumbs/models/breadcrumbs.model';
import { ITopBarLink } from '@libs/ui-kit/components/top-bar/models/top-bar-link.model';
import { createAction, props } from '@ngrx/store';
import { SnackbarConfig } from '../reducers/shared.reducer';

export enum SharedActionTypes {
  SHOW_SNACKBAR = '[Shared] Show snackbar',
  SHOW_SNACKBAR_SUCCESS = '[Shared] Show snackbar Success',
  CLEAR_SNACKBAR = '[Shared] Clear snackbar state',
  SET_LANGUAGE = '[Shared] Set language selection',
  UPDATE_USER_LANGUAGE = '[Shared] Update user language',
  SET_TOP_BAR_OTHER_LINKS = '[Shared] Set top bar other links',
  CLEAR_TOP_BAR_OTHER_LINKS = '[Shared] Clear top bar other links',
  SET_BREADCRUMBS = '[Shared] Set Breadcrumbs',
  CLEAR_BREADCRUMBS = '[Shared] Clear Breadcrumbs',
  SET_REDIRECT_URL = '[Shared] Set Redirect Url',
  SET_GLOBAL_LOADER = '[Shared] Set global loader',
  START_USER_SESSION_TIME_TRACKING = '[Shared] Start user session time tracking',
  UPDATE_USER_SESSION_TIME_TRACKING = '[Shared] Update user session time tracking',
}

export const showSnackBar = createAction(SharedActionTypes.SHOW_SNACKBAR, props<{ snackBarConfig: SnackbarConfig }>());

export const showSnackBarSuccess = createAction(SharedActionTypes.SHOW_SNACKBAR_SUCCESS);

export const clearSnackBar = createAction(SharedActionTypes.CLEAR_SNACKBAR);

export const setLanguage = createAction(SharedActionTypes.SET_LANGUAGE, props<{ language: Language }>());

export const updateUserLanguage = createAction(SharedActionTypes.UPDATE_USER_LANGUAGE, props<{ language: string }>());

export const setTopBarOtherLinks = createAction(
  SharedActionTypes.SET_TOP_BAR_OTHER_LINKS,
  props<{ links: ITopBarLink[] }>()
);

export const clearTopBarOtherLinks = createAction(SharedActionTypes.CLEAR_TOP_BAR_OTHER_LINKS);

export const setBreadCrumbs = createAction(SharedActionTypes.SET_BREADCRUMBS, props<{ breadcrumbs: IBreadcrumb[] }>());

export const clearBreadCrumbs = createAction(SharedActionTypes.CLEAR_BREADCRUMBS);

export const setRedirectUrl = createAction(SharedActionTypes.SET_REDIRECT_URL, props<{ url: string }>());

export const setGlobalLoader = createAction(SharedActionTypes.SET_GLOBAL_LOADER, props<{ loading: boolean }>());

export const startUserSessionTimeTracking = createAction(
  SharedActionTypes.START_USER_SESSION_TIME_TRACKING,
  props<{ delay: number }>()
);

export const updateUserSessionTimeTracking = createAction(
  SharedActionTypes.UPDATE_USER_SESSION_TIME_TRACKING,
  props<{
    date: string;
    timeTrackingItem: ISessionTimeTrackingModel;
    timeTrackingMap: Map<string, ISessionTimeTrackingModel>;
  }>()
);
