import { Injectable } from '@angular/core';
import { SharedAppState } from '@libs/common/store/store.interface';
import { IStaff, Staff } from '@libs/data/features/staff/models/staff.model';
import { IStudent, Student } from '@libs/data/features/student/models/student.model';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { User } from '@libs/data/features/user/models/user.model';
import { IUtm } from '@libs/data/features/user/models/utm.model';
import { Store } from '@ngrx/store';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import * as AuthActions from '../actions/auth.actions';
import { currentUser } from '../selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  user$ = this.store.select(currentUser).pipe(
    map((user) => {
      if (user) {
        return user.role === Role.STUDENT ? Student.Build(user as IStudent) : Staff.Build(user as IStaff);
      }

      return Student.Build({} as IStudent);
    })
  );

  constructor(private readonly store: Store<SharedAppState>, private readonly authService: AuthService) {}

  public refreshCurrentUser(): Observable<Student | Staff> {
    return this.authService.me().pipe(tap((user) => this.updateCurrentUser(user)));
  }

  public logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  public updateCurrentUser(user: User): void {
    this.store.dispatch(AuthActions.updateCurrentUser({ user }));
  }

  public singInWithSocial(loginProvider: firebase.auth.AuthProvider, utm?: IUtm): void {
    this.store.dispatch(AuthActions.signInWithSocial({ loginProvider, utm }));
  }
}
