import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    Sentry.captureException(error.originalError || error);

    if (typeof error.code === 'string' && !error.code?.includes('auth')) {
      console.error(error);
    } else {
      console.error(error);
    }
  }
}
