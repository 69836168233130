import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';

@Component({
  selector: 'ui-kit-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() currentUser = {} as Student | Staff;
}
