import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Icon } from '@libs/data/models/icon.model';
import { IBreadcrumb } from './models/breadcrumbs.model';

@Component({
  selector: 'ui-kit-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() set breadcrumbs(value: IBreadcrumb[]) {
    this._breadcrumbs = value;

    this.cd.detectChanges();
  }
  @Input() isLoading = true;

  public _breadcrumbs: IBreadcrumb[] = [];
  public accessibleIcon = Icon['HOME_ICON'];

  constructor(private readonly cd: ChangeDetectorRef) {}
}
