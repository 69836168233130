import { ContentType } from '../../unit/enums/content-type.enum';
import {
  IStudentUnitContentExample,
  StudentUnitContentExample,
} from '../models/content/student-unit-content-example.model';
import {
  IStudentUnitContentExercise,
  StudentUnitContentExercise,
} from '../models/content/student-unit-content-exercise.model';
import { IStudentUnitContentFile, StudentUnitContentFile } from '../models/content/student-unit-content-file.model';
import { IStudentUnitContentLink, StudentUnitContentLink } from '../models/content/student-unit-content-link.model';
import {
  IStudentUnitContentSurvey,
  StudentUnitContentSurvey,
} from '../models/content/student-unit-content-survey.model';
import { IStudentUnitContentTest, StudentUnitContentTest } from '../models/content/student-unit-content-test.model';
import { IStudentUnitContentVideo, StudentUnitContentVideo } from '../models/content/student-unit-content-video.model';
import { IStudentUnitContent } from '../models/content/student-unit-content.model';

export class StudentContentFactory {
  public static createContent(studentUnitContent: IStudentUnitContent) {
    const { type } = studentUnitContent;

    switch (type) {
      case ContentType.EXAMPLE:
        return StudentUnitContentExample.Build(studentUnitContent as IStudentUnitContentExample);
      case ContentType.FILE:
        return StudentUnitContentFile.Build(studentUnitContent as IStudentUnitContentFile);
      case ContentType.LINK:
        return StudentUnitContentLink.Build(studentUnitContent as IStudentUnitContentLink);
      case ContentType.VIDEO:
        return StudentUnitContentVideo.Build(studentUnitContent as IStudentUnitContentVideo);
      case ContentType.EXERCISE:
        return StudentUnitContentExercise.Build(studentUnitContent as IStudentUnitContentExercise);
      case ContentType.SURVEY:
        return StudentUnitContentSurvey.Build(studentUnitContent as IStudentUnitContentSurvey);
      case ContentType.TEST:
        return StudentUnitContentTest.Build(studentUnitContent as IStudentUnitContentTest);
      default:
        throw new Error('Invalid student unit content type: ' + type);
    }
  }
}
