import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authReducer } from '@libs/auth/store/reducers/auth.reducer';
import { SentryErrorHandler } from '@libs/common/services/sentry/sentry-error-handler.service';
import { SharedEffects } from '@libs/common/store/effects/shared.effects';
import { sharedReducer } from '@libs/common/store/reducers/shared.reducer';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CodeEditorModule } from '@ngstack/code-editor';
import * as Sentry from '@sentry/browser';
import { I18NEXT_SERVICE, I18NextModule, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import HttpApi from 'i18next-http-backend';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiHttpInterceptor } from './shared/services/api-http-interceptor';

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.environment,
});

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next.use(HttpApi).init({
      fallbackLng: 'en',
      debug: false,
      returnEmptyString: false,
      ns: ['translation'],
      interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
      },
      backend: {
        loadPath: './assets/i18n/{{lng}}.json',
      },
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

const appComponents = [AppComponent];

const appModules = [
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule,
  AngularFireAuthModule,
  HttpClientModule,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  AppRoutingModule,
  ToastrModule.forRoot({
    positionClass: 'toast-center-center',
    closeButton: true,
    disableTimeOut: true,
  }),
  LoggerModule.forRoot({
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.OFF,
  }),
  I18NextModule.forRoot(),
  AngularFireStorageModule,
  AngularFireAnalyticsModule,
  AngularFirePerformanceModule,
  CodeEditorModule.forRoot({
    baseUrl: 'assets/monaco',
  }),
  StoreModule.forRoot(
    {
      shared: sharedReducer,
      auth: authReducer,
    },
    {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }
  ),
  StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  EffectsModule.forRoot([SharedEffects]),
];
@NgModule({
  declarations: [...appComponents],
  imports: [...appModules],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true,
    },
    ScreenTrackingService,
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    PerformanceMonitoringService,
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule {}
