import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getSupportedLanguages } from '@libs/data/features/user/models/language.model';

@Component({
  selector: 'ui-kit-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  @Input() selectedLanguage = '';
  @Input() isLogged = true;
  @Input() isHome = false;
  @Output() setLanguage = new EventEmitter<string>();

  public languages = getSupportedLanguages();

  public handleOptionClick(option: string): void {
    this.selectedLanguage = option;
    this.setLanguage.emit(this.selectedLanguage);
  }
}
