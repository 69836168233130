import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@libs/common/services/modal/modal.service';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { ITopBarLink } from './models/top-bar-link.model';

@Component({
  selector: 'ui-kit-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  @Input() currentUser = {} as Student | Staff;
  @Input() otherLinks: ITopBarLink[] = [];
  @Input() topbarUrls = { chat: '', faq: '', notion: '', career: '' };
  @Input() selectedLanguage = '';

  @Output() logout = new EventEmitter<void>();
  @Output() redirectToHome = new EventEmitter<void>();
  @Output() redirectToSettings = new EventEmitter<void>();
  @Output() setLanguage = new EventEmitter<string>();

  public showMenu = false;

  constructor(private readonly modalService: ModalService) {}

  openFeatureDisabledDialog(): void {
    this.modalService.open({ title: 'Warning', description: 'feature-disabled.description', confirm: 'common.close' });
  }
}
