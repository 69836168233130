import { QuestionFactory } from '../../../test/factories/question.factory';
import { IQuestion, Question } from '../../../test/models/question/question.model';
import { ContentType } from '../../../unit/enums/content-type.enum';
import { StudentUnitContentSurveyStatus } from '../../enums/content/student-unit-content-survey-status.enum';
import { IStudentUnitContent, StudentUnitContent } from './student-unit-content.model';

export interface IStudentUnitContentSurvey extends IStudentUnitContent {
  status: StudentUnitContentSurveyStatus;
  questions: IQuestion[];
}

export class StudentUnitContentSurvey extends StudentUnitContent {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public status: StudentUnitContentSurveyStatus,
    public questions: Question[],
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(studentUnitContent: IStudentUnitContentSurvey): StudentUnitContentSurvey {
    const questions = studentUnitContent.questions?.map((question) => QuestionFactory.createQuestion(question)) || [];

    return new this(
      studentUnitContent.id,
      new Date(studentUnitContent.createdAt),
      new Date(studentUnitContent.updatedAt),
      studentUnitContent.title,
      studentUnitContent.description,
      studentUnitContent.type,
      studentUnitContent.blocked,
      studentUnitContent.status,
      questions,
    );
  }
}
