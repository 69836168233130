import {
  IQuestionQuiz,
  IUpdateContentQuestionQuizPayload,
  QuestionQuiz,
} from '../../../test/models/question/question-quiz.model';
import { ContentType } from '../../enums/content-type.enum';
import { Content, IContent } from './content.model';
import { IUpdateContentPayload } from './update-content-payload.model';

export interface IUpdateContentTestPayload extends Omit<IUpdateContentPayload, 'type'> {
  type: ContentType;
  questions: IUpdateContentQuestionQuizPayload[];
  tags?: string[];
  version?: number;
  estimateDuration?: number;
  blocker?: boolean;
}

export interface IContentTest extends IContent {
  questions: IQuestionQuiz[];
  tags?: string[];
  version?: number;
  estimateDuration?: number;
  blocker?: boolean;
}

export class ContentTest extends Content {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public questions: QuestionQuiz[],
    public tags?: string[],
    public version?: number,
    public estimateDuration?: number,
    public blocker?: boolean,
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(content: IContentTest): ContentTest {
    const questions = content.questions?.map((question) => QuestionQuiz.Build(question)) || [];

    return new this(
      content.id,
      new Date(content.createdAt),
      new Date(content.updatedAt),
      content.title,
      content.description,
      content.type,
      content.blocked,
      questions,
      content.tags,
      content.version,
      content.estimateDuration,
      content.blocker,
    );
  }
}
