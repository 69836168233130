import { Injectable } from '@angular/core';
import { from, switchMap, take } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseFacade {
  public authState$ = this.firebaseService.authState();
  public userToken$ = this.authState$.pipe(
    take(1),
    switchMap(() => from(this.firebaseService.getTokens()))
  );

  constructor(private readonly firebaseService: FirebaseService) {}
}
