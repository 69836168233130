export enum Language {
  IT = 'it',
  EN = 'en',
  ES = 'es',
  TR = 'tr'
}

export const languageMap = new Map<Language, string>([
  [Language.IT, 'Italiano'],
  [Language.EN, 'English'],
  [Language.ES, 'Español'],
  [Language.TR, 'Türkçe']
])

export class Languages {
  it: string;
  en: string;
  es: string;
  tr: string;

  constructor() {
    this.it = '';
    this.en = '';
    this.es = '';
    this.tr = '';
  }
}

export class LanguagesDetail {
  it: { title: string; description: string };
  en: { title: string; description: string };
  es: { title: string; description: string };
  tr: { title: string; description: string };

  constructor() {
    this.it = { title: '', description: '' };
    this.en = { title: '', description: '' };
    this.es = { title: '', description: '' };
    this.tr = { title: '', description: '' };
  }
}

export class LanguagesMultipleDetails {
  it: { title: string; description: string }[];
  en: { title: string; description: string }[];
  es: { title: string; description: string }[];
  tr: { title: string; description: string }[];

  constructor() {
    this.it = [];
    this.en = [];
    this.es = [];
    this.tr = [];
  }
}

export const getSupportedLanguages = (): string[] => Object.keys(new Languages());
export const getLanguage = (isAuth: boolean): Language => {
  const langItem = localStorage.getItem('language');
  if (!isAuth) {
    if (langItem) {
      return getSupportedLanguages().includes(langItem) ? <Language>langItem : Language.EN;
    } else {
      const navLanguage = navigator.language.slice(0, 2);
      return getSupportedLanguages().includes(navLanguage) ? <Language>navLanguage : Language.EN;
    }
  } else {
    return <Language>langItem;
  }
};

export const getDefaultLanguages = (): Languages => new Languages();
export const getDefaultLanguagesDetail = (): LanguagesDetail => new LanguagesDetail();
export const getDefaultLanguagesMultipleDetail = (): LanguagesMultipleDetails => new LanguagesMultipleDetails();
