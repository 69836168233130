import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapToStaffOrStudent } from '@libs/common/utils/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedModel } from '../../../models/pagination.model';
import { Permission } from '../../permissions/enums/permission.enum';
import { Staff } from '../../staff/models/staff.model';
import { Student } from '../../student/models/student.model';
import { Role } from '../enums/role.enum';
import { IUpdateUserPayload } from '../models/user-payload.model';
import { IUser, User } from '../models/user.model';
import { V1AuthRepository } from '../repositories/v1/auth.repository';
import { V1UsersRepository } from '../repositories/v1/users.repository';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly authRepository: V1AuthRepository,
    private readonly usersRepository: V1UsersRepository,
  ) {}

  public getUsers(page?: number, search?: string, roles?: Role[], pageLen?: number): Observable<PaginatedModel<User>> {
    return this.usersRepository
      .getUsers(page, search, roles?.map((role) => role.toLowerCase())?.join(','), pageLen)
      .pipe(
        map((response) => ({
          ...response,
          data: response.data.map(mapToStaffOrStudent),
        })),
      );
  }

  public getUserById(id: string): Observable<Student | Staff> {
    return this.usersRepository
      .getUserById(id)
      .pipe(map((response) => (response.role === Role.STUDENT ? Student.Build(response) : Staff.Build(response))));
  }

  public updateUserById(user: IUpdateUserPayload): Observable<Student | Staff> {
    return this.usersRepository
      .updateUserById(user)
      .pipe(map((response) => (response.role === Role.STUDENT ? Student.Build(response) : Staff.Build(response))));
  }

  public updateMe(user: IUser): Observable<Student | Staff> {
    return this.authRepository
      .updateMe(user)
      .pipe(map((response) => (response.role === Role.STUDENT ? Student.Build(response) : Staff.Build(response))));
  }

  public setUserPermissions(userId: string, permissions: Permission[]): Observable<void> {
    return this.usersRepository.setUserPermissions(userId, permissions);
  }
}
