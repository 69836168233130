export enum ContentType {
  EXAMPLE = 'example',
  EXERCISE = 'exercise',
  FILE = 'file',
  LINK = 'link',
  SURVEY = 'survey',
  TEST = 'test',
  VIDEO = 'video',
}

export enum ContentTypeProvider {
  YOUTUBE = 'youtube',
  INTERNAL = 'internal',
}

export const CONTENT_TYPE_PROVIDER = Object.values(ContentTypeProvider);
