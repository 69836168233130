import { QuestionType } from '../../enums/question-type.enum';
import { IQuestion, Question } from './question.model';

export interface IQuestionDate extends IQuestion {
  studentAnswer?: string;
}

export class QuestionDate extends Question {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override description: string,
    public override question: string,
    public override type: QuestionType,
    public studentAnswer?: string
  ) {
    super(id, createdAt, updatedAt, description, question, type);
  }

  public static Build(question: IQuestionDate): QuestionDate {
    return new this(
      question.id,
      new Date(question.createdAt),
      new Date(question.updatedAt),
      question.description,
      question.question,
      question.type,
      question.studentAnswer
    );
  }
}
