import { ContentFactory } from '../factories/content.factory';
import { Content, IContent } from './content/content.model';

export interface ILesson {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  index: number;
  description: string;
  contents: IContent[];
}

export class Lesson {
  constructor(
    public id: string,
    public createdAt: Date,
    public updatedAt: Date,
    public title: string,
    public index: number,
    public description: string,
    public contents: Content[]
  ) {}

  public static Build(lesson: ILesson): Lesson {
    const contents = lesson.contents?.map((content) => ContentFactory.createContent(content));

    return new Lesson(
      lesson.id,
      new Date(lesson.createdAt),
      new Date(lesson.updatedAt),
      lesson.title,
      lesson.index,
      lesson.description,
      contents
    );
  }
}
