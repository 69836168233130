import { IUpdateContentPayload } from '../../../unit/models/content/update-content-payload.model';
import { QuestionType } from '../../enums/question-type.enum';
import { IQuestion, Question } from './question.model';

export interface IUpdateContentQuestionQuizPayload extends Omit<IUpdateContentPayload, 'type' | 'title'> {
  type: QuestionType.QUIZ;
  answers: string[];
  correctAnswer: string;
  question: string;
}

export interface IQuestionQuiz extends IQuestion {
  answers: string[];
  correctAnswer?: string;
  studentAnswer?: string;
}

export class QuestionQuiz extends Question {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override description: string,
    public override question: string,
    public override type: QuestionType,
    public answers: string[],
    public correctAnswer?: string,
    public studentAnswer?: string
  ) {
    super(id, createdAt, updatedAt, description, question, type);
  }

  public static Build(question: IQuestionQuiz): QuestionQuiz {
    return new this(
      question.id,
      new Date(question.createdAt),
      new Date(question.updatedAt),
      question.description,
      question.question,
      question.type,
      question.answers,
      question.correctAnswer,
      question.studentAnswer
    );
  }
}
