import { IStudentHomepageUnit, StudentHomepageUnit } from './student-homepage-unit.model';

export interface IStudentDashboardUnitType {
  id: string;
  type: string;
  units: StudentHomepageUnit[];
}

export class StudentDashboardUnitType {
  protected constructor(
    public id: string,
    public type: string,
    public units: IStudentHomepageUnit[],
  ) {}

  public static Build(dashboardUnitType: IStudentDashboardUnitType) {
    return new StudentDashboardUnitType(
      dashboardUnitType.id,
      dashboardUnitType.type,
      dashboardUnitType.units.map((unit) => StudentHomepageUnit.Build(unit)),
    );
  }
}
