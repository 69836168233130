import { StudentUnitStatus } from '../enums/student-unit-status.enum';
import { IStudentStats } from './student-stats.model';

export interface IStudentHomepageUnit {
  id: string;
  type: string;
  name: string;
  language: string;
  status: StudentUnitStatus;
  duration: number;
  stats: IStudentStats;
  blocked?: boolean;
}

export class StudentHomepageUnit {
  constructor(
    public id: string,
    public type: string,
    public name: string,
    public language: string,
    public status: StudentUnitStatus,
    public duration: number,
    public stats: IStudentStats,
    public blocked?: boolean,
  ) {}

  public static Build(unit: IStudentHomepageUnit): StudentHomepageUnit {
    return new StudentHomepageUnit(
      unit.id,
      unit.type,
      unit.name,
      unit.language,
      unit.status,
      unit.duration,
      unit.stats,
      unit.blocked,
    );
  }
}
