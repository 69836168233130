<ng-container *ngIf="!isLoading; else skeleton">
  <div *ngIf="_breadcrumbs.length" class="breadcrumbs">
    <ng-container *ngFor="let page of _breadcrumbs; let i = index">
      <ng-container *ngIf="i === 0; else otherBreadcrumb">
        <a [routerLink]="page.active ? page.url : null" [queryParamsHandling]="page.queryParamsHandling">
          <img [src]="accessibleIcon.src" [alt]="accessibleIcon.alt | i18nextEager" class="breadcrumbs-home" />
        </a>
      </ng-container>

      <ng-template #otherBreadcrumb>
        <img src="assets/images/icons/arrow-next-breadcrumb.svg" alt="Arrow next" class="breadcrumbs-arrow" />
        <a
          class="breadcrumbs-link"
          [routerLink]="page.active ? page.url : null"
          [queryParamsHandling]="page.queryParamsHandling"
          [ngClass]="{ current: !page.active }"
        >
          {{ page.name | i18nextEager : { count: 0 } | titlecase }}
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    count="1"
    appearance="circle"
    [theme]="{ width: '100%', height: '45px', 'border-radius': '5px' }"
    class="skeleton-loader-item"
  >
  </ngx-skeleton-loader>
</ng-template>
