import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser, IUserSignupPayload } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class V1AuthRepository {
  constructor(private readonly http: HttpClient) {}

  public me(): Observable<IUser> {
    return this.http.get<IUser>(`/v1/me`);
  }

  public updateMe(user: Partial<IUser>): Observable<IUser> {
    return this.http.patch<IUser>(`/v1/me`, user);
  }

  public signUp(payload: IUserSignupPayload): Observable<IUser> {
    return this.http.post<IUser>(`/v1/auth/signup`, payload);
  }
}
