import { ContentType } from '../../../unit/enums/content-type.enum';

export interface IStudentUnitContent {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  type: ContentType;
  blocked: boolean;
}

export abstract class StudentUnitContent {
  protected constructor(
    public id: string,
    public createdAt: Date,
    public updatedAt: Date,
    public title: string,
    public description: string,
    public type: ContentType,
    public blocked: boolean,
  ) {}
}
