import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { IUser } from '@libs/data/features/user/models/user.model';

export const isStudent = (role: Role): boolean => {
  const roles = new Set([Role.STUDENT]);

  return roles.has(role);
};

export const mapToStaffOrStudent = (user: IUser): Staff | Student =>
  isStudent(user.role) ? Student.Build(user) : Staff.Build(user);
