import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authEnvironment } from '@libs/auth/environments/environment';
import { LoginGuard } from '@libs/auth/guards/login.guard';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';

const authenticationModule = () => import('@libs/auth/auth.module').then(({ AuthModule }) => AuthModule);
const homepageModule = () => import('./modules/homepage/homepage.module').then(({ HomepageModule }) => HomepageModule);
const unitsModule = () => import('./modules/units/units.module').then(({ UnitsModule }) => UnitsModule);
const unitTypesModule = () =>
  import('./modules/unit-types/unit-types.module').then(({ UnitTypesModule }) => UnitTypesModule);
const usersModule = () => import('./modules/users/users.module').then(({ UsersModule }) => UsersModule);
const classesModule = () => import('./modules/classes/classes.module').then(({ ClassesModule }) => ClassesModule);
const classTypesModule = () =>
  import('./modules/class-types/class-types.module').then(({ ClassTypesModule }) => ClassTypesModule);
const classTemplateModule = () =>
  import('./modules/class-templates/class-template.module').then(({ ClassTemplateModule }) => ClassTemplateModule);
const classTagsModule = () =>
  import('./modules/class-tags/class-tags.module').then(({ ClassTagsModule }) => ClassTagsModule);
const settingsModule = () => import('./modules/settings/settings.module').then(({ SettingsModule }) => SettingsModule);
const supervisorModule = () =>
  import('./modules/supervisor/supervisor.module').then(({ SupervisorModule }) => SupervisorModule);

const routes: Routes = [
  {
    path: '',
    data: {
      loginType: authEnvironment.staffPlatform.loginType,
    },
    loadChildren: authenticationModule,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginGuard],
    children: [
      { path: 'homepage', loadChildren: homepageModule },
      { path: 'units', loadChildren: unitsModule },
      { path: 'unit-types', loadChildren: unitTypesModule },
      { path: 'users', loadChildren: usersModule },
      { path: 'classes', loadChildren: classesModule },
      { path: 'class-types', loadChildren: classTypesModule },
      { path: 'class-template', loadChildren: classTemplateModule },
      { path: 'class-tags', loadChildren: classTagsModule },
      { path: 'settings', loadChildren: settingsModule },
      { path: 'exercises', loadChildren: supervisorModule },
    ],
  },
  { path: '**', redirectTo: 'dashboard/homepage', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
