import { createSelector } from '@ngrx/store';
import { SharedAppState } from '../store.interface';

export const selectSharedState = (state: SharedAppState) => state.shared;

export const showSnackBar = createSelector(selectSharedState, ({ showSnackBar }) => showSnackBar);

export const snackBarConfig = createSelector(selectSharedState, ({ snackBarConfig }) => snackBarConfig);

export const language = createSelector(selectSharedState, ({ language }) => language);

export const topBarOtherLinks = createSelector(selectSharedState, ({ topBarOtherLinks }) => topBarOtherLinks);

export const breadcrumbs = createSelector(selectSharedState, ({ breadcrumbs }) => breadcrumbs);

export const globalLoader = createSelector(selectSharedState, ({ loading }) => loading);
