import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@libs/auth/services/auth.service';
import { AuthFacade } from '@libs/auth/store/facades/auth.facade';
import { PublicService } from '@libs/common/services/public/public.service';
import { SharedFacade } from '@libs/common/store/facades/shared.facade';
import { ClassAction } from '@libs/data/features/permissions/enums/class-action.enum';
import { ExerciseAction } from '@libs/data/features/permissions/enums/exercise-action.enum';
import { Feature } from '@libs/data/features/permissions/enums/feature.enum';
import { SettingsAction } from '@libs/data/features/permissions/enums/settings-action.enum';
import { UnitAction } from '@libs/data/features/permissions/enums/unit-action.enum';
import { UserAction } from '@libs/data/features/permissions/enums/user-action.enum';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { IBreadcrumb } from '@libs/ui-kit/components/breadcrumbs/models/breadcrumbs.model';
import { ITopBarLink } from '@libs/ui-kit/components/top-bar/models/top-bar-link.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public breadcrumbs: IBreadcrumb[] = [];
  public currentUser: Student | Staff;
  public otherLinks$: Observable<ITopBarLink[]> = this.sharedFacade.topBarOtherLinks$;
  public topbarUrls: { chat: string; faq: string; notion: string } = {
    chat: '',
    faq: '',
    notion: '',
  };
  public selectedLanguage = '';
  public loading = true;

  private topBarOtherLinks: ITopBarLink[] = [
    { name: 'Settings', path: '/dashboard/settings', feature: Feature.SETTINGS, actions: [SettingsAction.LIST] },
    { name: 'Units', path: '/dashboard/units', feature: Feature.UNIT, actions: [UnitAction.LIST] },
    {
      name: 'Users',
      path: '/dashboard/users',
      feature: Feature.USER,
      actions: [
        UserAction.LIST_ADMINS,
        UserAction.LIST_COACHES,
        UserAction.LIST_COORDINATORS,
        UserAction.LIST_EDITORS,
        UserAction.LIST_STUDENTS,
        UserAction.LIST_TUTORS,
      ],
    },
    { name: 'Classes', path: '/dashboard/classes', feature: Feature.CLASS, actions: [ClassAction.LIST] },
    {
      name: 'Exercises',
      path: '/dashboard/exercises',
      feature: Feature.EXERCISE,
      actions: [ExerciseAction.LIST_UNITS],
    },
  ];
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly sharedFacade: SharedFacade,
    private readonly publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.topbarUrls.chat = this.publicService.getChatUrl(this.currentUser.country);
        this.topbarUrls.faq = this.publicService.getFaqUrl(this.currentUser.language);
        this.topbarUrls.notion = this.publicService.getNotionUrl(this.currentUser.language);

        this.sharedFacade.setTopBarOtherLinks(this.topBarOtherLinks);
      }
    });

    this.sharedFacade.breadcrumbs$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbs) => {
      if (breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
        this.loading = false;
      }
    });

    this.sharedFacade.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lan) => (this.selectedLanguage = lan as string));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleRedirectToHome(): void {
    this.router.navigateByUrl('/dashboard/homepage');
  }

  public handleRedirectToSettings(): void {
    this.router.navigateByUrl(this.redirectToSettings);
  }

  public updateUserLanguage(language: string): void {
    this.sharedFacade.updateUserLanguage(language);
  }

  public logout(): void {
    this.authService.logout();
  }

  private get redirectToSettings(): string {
    switch (this.currentUser.role) {
      case Role.TUTOR:
        return '/tutor/profile/settings';
      default:
        return '/staff/profile/settings';
    }
  }
}
