import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { CacheService } from '@libs/common/services/cache/cache.service';
import { IDeviceInfo } from '../models/device-info.model';
import { LocalStorageKey } from '../../../enums/local-storage-key.enum';
@Injectable({
  providedIn: 'root',
})
export class UserAgentService extends CacheService {
  private payload: IDeviceInfo = {
    browser: {
      name: '',
      version: '',
      major: '',
    },
    device: {
      type: '',
      vendor: '',
      model: '',
    },
    engine: {
      name: '',
      version: '',
    },
    os: {
      name: '',
      version: '',
    },
  };

  setSessionInfo() {
    const ua = navigator.userAgent;
    const parser = new UAParser(ua);
    const uaInfo = parser.getResult();
    const isDesktop = uaInfo.device.type === undefined || !['wearable', 'mobile'].includes(uaInfo.device.type);

    this.payload.device = uaInfo.device;
    this.payload.os = uaInfo.os;
    this.payload.browser = uaInfo.browser;
    this.payload.engine = uaInfo.engine;

    if (isDesktop) {
      this.payload.device.type = 'desktop';
    }

    this.setItem(LocalStorageKey.DEVICE_INFO, this.payload);
  }

  getSessionInfo(): IDeviceInfo {
    let sessionInfo = this.getItem(LocalStorageKey.DEVICE_INFO);
    if (sessionInfo) {
      return JSON.parse(sessionInfo) as IDeviceInfo;
    } else {
      this.setSessionInfo();
      sessionInfo = this.getItem(LocalStorageKey.DEVICE_INFO) as string;
      return JSON.parse(sessionInfo) as IDeviceInfo;
    }
  }
}
