import { Nullable } from '@libs/common/types/nullable.type';
import { Permission } from '../../permissions/enums/permission.enum';
import { Gender } from '../../user/enums/gender.enum';
import { Role } from '../../user/enums/role.enum';
import { IEmergencyContact } from '../../user/models/emergency-contact.model.';
import { IUser, User } from '../../user/models/user.model';
import { IUtm } from '../../user/models/utm.model';

export interface IStaffPersonalData {
  age: number;
  attribution: string;
  currentCity: string;
  dateOfBirth: string;
  education: string;
  emergencyContact: Nullable<IEmergencyContact>;
  englishKnowledge: string;
  gender: Gender;
  motivation: string;
  nationality: string;
  occupation: string;
  phoneNumber: string;
  profilePhotoUrl: string;
  programmingKnowledge: string;
}

export interface IStaff extends IUser {
  personalData?: IStaffPersonalData;
}

export class Staff extends User {
  constructor(
    public override id = '',
    public override createdAt = '',
    public override updatedAt = '',
    public override bannedAt = '',
    public override verifiedAt = '',
    public override lastAccessAt = '',
    public override permissions: Nullable<Permission[]> = null,
    public override role = Role.ADMIN,
    public override tcAcceptedAt = '',
    public override tcAcceptedVersion = '',
    public override newsletterAccepted = false,
    public override firstname = '',
    public override lastname = '',
    public override country = '',
    public override language = 'it',
    public override email = '',
    public override verified = false,
    public override timezone = '',
    public override utm: Nullable<IUtm> = null,
    public override githubUrl = '',
    public personalData = {
      age: null,
      attribution: 'none',
      currentCity: '',
      dateOfBirth: '',
      education: 'none',
      emergencyContact: null,
      englishKnowledge: '',
      gender: Gender.Other,
      motivation: '',
      nationality: '',
      occupation: 'none',
      phoneNumber: '',
      profilePhotoUrl: '',
      programmingKnowledge: 'none',
    } as unknown as IStaffPersonalData,
  ) {
    super(
      id,
      createdAt,
      updatedAt,
      bannedAt,
      verifiedAt,
      lastAccessAt,
      permissions,
      role,
      tcAcceptedAt,
      tcAcceptedVersion,
      newsletterAccepted,
      firstname,
      lastname,
      country,
      language,
      email,
      verified,
      timezone,
      utm,
      githubUrl,
    );
  }

  public static Build(staff: IStaff = {} as IStaff): Staff {
    return new this(
      staff.id,
      staff.createdAt,
      staff.updatedAt,
      staff.bannedAt,
      staff.verifiedAt,
      staff.lastAccessAt,
      staff.permissions,
      staff.role,
      staff.tcAcceptedAt,
      staff.tcAcceptedVersion,
      staff.newsletterAccepted,
      staff.firstname,
      staff.lastname,
      staff.country,
      staff.language?.toLowerCase(),
      staff.email,
      staff.verified,
      staff.timezone,
      staff.utm,
      staff.githubUrl,
      staff.personalData,
    );
  }
}
