import { ExerciseMode } from '../../../exercise/enums/exercise-mode.enum';
import { ContentType } from '../../../unit/enums/content-type.enum';
import { ProgrammingLanguage } from '../../../unit/enums/programming-language.enum';
import { StudentUnitContentExerciseStatus } from '../../enums/content/student-unit-content-exercise-status.enum';
import { IStudentUnitContent, StudentUnitContent } from './student-unit-content.model';

export interface IStudentUnitContentExercise extends IStudentUnitContent {
  url?: string;
  mode?: ExerciseMode;
  needsAnswer?: boolean;
  suggestion?: string;
  programmingLanguage?: ProgrammingLanguage;
  references?: string[];
  result?: string;
  videoCorrectionUrl?: string;
  status?: StudentUnitContentExerciseStatus;
  note?: string;
  vote?: number;
  studentResult?: string;
  aiFeedbackGrade?: number;
  aiFeedbackEvaluation?: string;
}

export class StudentUnitContentExercise extends StudentUnitContent {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public url?: string,
    public mode?: ExerciseMode,
    public needsAnswer?: boolean,
    public programmingLanguage?: ProgrammingLanguage,
    public references?: string[],
    public result?: string,
    public videoCorrectionUrl?: string,
    public suggestion?: string,
    public status?: StudentUnitContentExerciseStatus,
    public note?: string,
    public vote?: number,
    public studentResult?: string,
    public aiFeedbackGrade?: number,
    public aiFeedbackEvaluation?: string,
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(studentUnitContent: IStudentUnitContentExercise): StudentUnitContentExercise {
    return new this(
      studentUnitContent.id,
      new Date(studentUnitContent.createdAt),
      new Date(studentUnitContent.updatedAt),
      studentUnitContent.title,
      studentUnitContent.description,
      studentUnitContent.type,
      studentUnitContent.blocked,
      studentUnitContent.url,
      studentUnitContent.mode,
      studentUnitContent.needsAnswer,
      studentUnitContent.programmingLanguage,
      studentUnitContent.references,
      studentUnitContent.result,
      studentUnitContent.videoCorrectionUrl,
      studentUnitContent.suggestion,
      studentUnitContent.status || StudentUnitContentExerciseStatus.TO_DO,
      studentUnitContent.note,
      studentUnitContent.vote,
      studentUnitContent.studentResult,
      studentUnitContent.aiFeedbackGrade,
      studentUnitContent.aiFeedbackEvaluation,
    );
  }
}
