import { Injectable } from '@angular/core';
import { Language } from '@libs/data/features/user/models/language.model';
import { IBreadcrumb } from '@libs/ui-kit/components/breadcrumbs/models/breadcrumbs.model';
import { ITopBarLink } from '@libs/ui-kit/components/top-bar/models/top-bar-link.model';
import { Store } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import * as SharedActions from '../actions/shared.actions';
import { SnackbarConfig } from '../reducers/shared.reducer';
import {
  breadcrumbs,
  globalLoader,
  language,
  showSnackBar,
  snackBarConfig,
  topBarOtherLinks,
} from '../selectors/shared.selectors';
import { SharedAppState } from '../store.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedFacade {
  public showSnackBar$ = this.store.select(showSnackBar);
  public snackBarConfig$ = this.store.select(snackBarConfig);
  public language$ = this.store.select(language);
  public loading$ = this.store.select(globalLoader);
  public topBarOtherLinks$ = this.store.select(topBarOtherLinks).pipe(delay(0));
  public breadcrumbs$ = this.store.select(breadcrumbs).pipe(delay(0));

  constructor(private readonly store: Store<SharedAppState>) {}

  public showSnackBar(snackBarConfig: SnackbarConfig): void {
    this.store.dispatch(SharedActions.showSnackBar({ snackBarConfig }));
  }

  public showSuccessSnackBar(message: string): void {
    const snackBarConfig: SnackbarConfig = {
      message,
      options: {
        closeButton: false,
        positionClass: 'toast-bottom-center',
        disableTimeOut: false,
      },
      type: 'success',
    };
    this.store.dispatch(SharedActions.showSnackBar({ snackBarConfig }));
  }

  public clearSnackBar(): void {
    this.store.dispatch(SharedActions.clearSnackBar());
  }

  public setLanguage(language: Language): void {
    this.store.dispatch(SharedActions.setLanguage({ language }));
  }

  public updateUserLanguage(language: string): void {
    this.store.dispatch(SharedActions.updateUserLanguage({ language }));
  }

  public setTopBarOtherLinks(links: ITopBarLink[]): void {
    this.store.dispatch(SharedActions.setTopBarOtherLinks({ links }));
  }

  public clearTopBarLinks(): void {
    this.store.dispatch(SharedActions.clearTopBarOtherLinks());
  }

  public setBreadCrumbs(breadcrumbs: IBreadcrumb[]): void {
    this.store.dispatch(SharedActions.setBreadCrumbs({ breadcrumbs }));
  }

  public clearBreadCrumbs(): void {
    this.store.dispatch(SharedActions.clearBreadCrumbs());
  }

  public setRedirectUrl(url: string): void {
    this.store.dispatch(SharedActions.setRedirectUrl({ url }));
  }

  public setGlobalLoader(loading = true): void {
    this.store.dispatch(SharedActions.setGlobalLoader({ loading }));
  }

  public startUserSessionTimeTracking(delay: number): void {
    this.store.dispatch(SharedActions.startUserSessionTimeTracking({ delay }));
  }
}
