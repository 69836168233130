export interface IStudentUnitType {
  id: string;
  type: string;
}

export class StudentUnitType {
  constructor(public id: string, public type: string) {}

  public static Build(unitType: IStudentUnitType): StudentUnitType {
    return new StudentUnitType(unitType.id, unitType.type);
  }
}
