<div ngbDropdown>
  <button
    class="btn btn-dropdown"
    id="contentType"
    ngbDropdownToggle
    aria-expanded="false"
    [title]="selectedLanguage | i18nextEager"
    [ngClass]="{ 'bcg-dark': !isLogged, 'no-shadow': isHome }"
  >
    <img
      [src]="isLogged ? 'assets/images/icons/language.svg' : 'assets/images/icons/language-white.svg'"
      alt="language"
    />
    <span class="px-3">{{ selectedLanguage | langExtend | truncate : 3 : false }}</span>
    <img
      class="arrow-down"
      [src]="isLogged ? 'assets/images/icons/arrow-down-small.svg' : 'assets/images/icons/arrow-down-white.svg'"
      alt="down"
    />
  </button>
  <div
    ngbDropdownMenu
    aria-labelledby="contentType"
    class="dropdown-box mt-3 rounded"
    [ngClass]="{ 'bcg-dark': !isLogged, 'dropdown-box-grey': isLogged }"
  >
    <button
      ngbDropdownItem
      class="dropdown-item"
      *ngFor="let lang of languages"
      (click)="handleOptionClick(lang)"
      [title]="lang"
      [ngClass]="{ 'bcg-dark': !isLogged, 'lang-in-use': lang === selectedLanguage }"
    >
      {{ lang | langExtend }}
    </button>
  </div>
</div>
