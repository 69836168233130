import { Role } from '../../user/enums/role.enum';

export enum UserAction {
  DETAIL = 'detail',
  EDIT_PERMISSIONS = 'edit-permissions',
  BAN = 'ban',
  UNBAN = 'unban',
  IMPERSONATE = 'impersonate',
  LIST_STUDENTS = 'list-students',
  LIST_TUTORS = 'list-tutors',
  LIST_COORDINATORS = 'list-coordinators',
  LIST_COACHES = 'list-coaches',
  LIST_EDITORS = 'list-editors',
  LIST_ADMINS = 'list-admins',
  EDIT_STUDENTS = 'edit-students',
  EDIT_TUTORS = 'edit-tutors',
  EDIT_COORDINATORS = 'edit-coordinators',
  EDIT_COACHES = 'edit-coaches',
  EDIT_EDITORS = 'edit-editors',
  EDIT_ADMINS = 'edit-admins',
}

export const getUserActionByRole = (role: Role) => {
  const editRoleMap = {
    [Role.ADMIN]: UserAction.EDIT_ADMINS,
    [Role.COACH]: UserAction.EDIT_COACHES,
    [Role.EDITOR]: UserAction.EDIT_EDITORS,
    [Role.STUDENT]: UserAction.EDIT_STUDENTS,
    [Role.TUTOR]: UserAction.EDIT_TUTORS,
    [Role.COORDINATOR]: UserAction.EDIT_COORDINATORS,
  };

  return editRoleMap[role as keyof typeof editRoleMap];
};
