import { Pipe, PipeTransform } from '@angular/core';
import { Language, languageMap } from '@libs/data/features/user/models/language.model';

@Pipe({
  name: 'langExtend',
})
export class LangExtendPipe implements PipeTransform {
  transform(value: string): string {
    return languageMap.get(value as Language) ?? 'Italiano';
  }
}
