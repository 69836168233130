import { QuestionType } from '../../enums/question-type.enum';

export type StudentAnswerType = string | number | string[] | undefined;

export interface IQuestion {
  id: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  question: string;
  type: QuestionType;
}

export abstract class Question {
  protected constructor(
    public id: string,
    public createdAt: Date,
    public updatedAt: Date,
    public description: string,
    public question: string,
    public type: QuestionType
  ) {}
}
