import { QuestionType } from '../../enums/question-type.enum';
import { IQuestion, Question } from './question.model';

export interface IQuestionCheckbox extends IQuestion {
  answers: string[];
  correctAnswers?: string;
  studentAnswers?: string[];
}

export class QuestionCheckbox extends Question {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override description: string,
    public override question: string,
    public override type: QuestionType,
    public answers: string[],
    public correctAnswers?: string,
    public studentAnswers?: string[]
  ) {
    super(id, createdAt, updatedAt, description, question, type);
  }

  public static Build(question: IQuestionCheckbox): QuestionCheckbox {
    return new this(
      question.id,
      new Date(question.createdAt),
      new Date(question.updatedAt),
      question.description,
      question.question,
      question.type,
      question.answers,
      question.correctAnswers,
      question.studentAnswers
    );
  }
}
