import { ExercisesCorrectionMode } from '../../unit/enums/exercise-correction-mode.enum';
import { StudentUnitStatus } from '../enums/student-unit-status.enum';
import { IStudentStats } from './student-stats.model';
import { IStudentUnitLesson, StudentUnitLesson } from './student-unit-lesson.model';

export interface IStudentUnit {
  id: string;
  status: StudentUnitStatus;
  name: string;
  duration: number;
  language: string;
  type: string;
  stats: IStudentStats;
  lessons: IStudentUnitLesson[];
  exercisesCorrectionMode: string;
  technology: string;
  blocked?: boolean;
}

export class StudentUnit {
  constructor(
    public id: string,
    public status: StudentUnitStatus,
    public name: string,
    public duration: number,
    public language: string,
    public type: string,
    public stats: IStudentStats,
    public lessons: StudentUnitLesson[],
    public exercisesCorrectionMode: ExercisesCorrectionMode,
    public technology: string,
    public blocked?: boolean,
  ) {}

  public static Build(studentUnit: IStudentUnit): StudentUnit {
    const lessons =
      studentUnit.lessons?.map((lesson) => StudentUnitLesson.Build(lesson))?.sort((a, b) => a.index - b.index) || [];

    return new this(
      studentUnit.id,
      studentUnit.status,
      studentUnit.name,
      studentUnit.duration,
      studentUnit.language,
      studentUnit.type,
      studentUnit.stats,
      lessons,
      studentUnit.exercisesCorrectionMode as ExercisesCorrectionMode,
      studentUnit.technology,
      studentUnit.blocked,
    );
  }
}
