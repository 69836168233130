import { Nullable } from '../../types/nullable.type';

export abstract class CacheService {
  protected getItem(key: string, storage: Storage = localStorage): Nullable<string> {
    return storage.getItem(key);
  }

  protected setItem(key: string, data: object | string, storage: Storage = localStorage): void {
    typeof data === 'string' ? storage.setItem(key, data) : storage.setItem(key, JSON.stringify(data));
  }

  protected removeItem(key: string, storage: Storage = localStorage): void {
    storage.removeItem(key);
  }

  protected clear(storage: Storage = localStorage): void {
    storage.clear();
  }
}
