<div class="main-wrapper">
  <div class="loader-area" *ngIf="loading$ | async">
    <div class="loader">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="/assets/images/json/loader.json"
        style="width: 180px"
      ></lottie-player>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
