import { Injectable } from '@angular/core';
import { InternationalPublicLink, PublicLink, PublicLinkType } from '@libs/data/enums/public-link.enum';
import { Country } from '@libs/data/features/user/enums/country.enum';
import { Language } from '@libs/data/features/user/models/language.model';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  private publicUrls: Map<PublicLink, InternationalPublicLink>;

  constructor() {
    this.publicUrls = new Map<PublicLink, InternationalPublicLink>()
      .set(PublicLink.FAQ, {
        fallback: 'https://intercom.help/develhope/en',
        language: {
          [Language.IT]: 'https://intercom.help/develhope/it',
          [Language.EN]: 'https://intercom.help/develhope/en',
          fallback: 'https://intercom.help/develhope/en',
        },
      })
      .set(PublicLink.CHAT, {
        fallback: 'https://discord.com/invite/9cTGGucaBM',
        country: {
          [Country.IT]: 'https://discord.com/invite/tGQ3MhdSnA',
          [Country.ES]: 'https://discord.gg/xPANu5hVKV',
          [Country.TR]: 'https://discord.com/invite/yJpQtPG72N',
          fallback: 'https://discord.com/invite/9cTGGucaBM',
        },
      })
      .set(PublicLink.PRIVACY_POLICY, {
        fallback: 'https://talent.develhope.co/privacy_policy_en',
        language: {
          [Language.IT]: 'https://talent.develhope.co/privacy_policy',
          [Language.EN]: 'https://talent.develhope.co/privacy_policy_en',
          [Language.ES]: 'https://talent.develhope.co/privacy_policy_esp',
          [Language.TR]: 'https://talent.develhope.co/privacy_policy_tr',
          fallback: 'https://talent.develhope.co/privacy_policy_en',
        },
      })
      .set(PublicLink.TERMS_AND_CONDITIONS, {
        fallback: 'https://talent.develhope.co/terms_and_conditions_en',
        language: {
          [Language.IT]: 'https://talent.develhope.co/terms_and_conditions',
          [Language.EN]: 'https://talent.develhope.co/terms_and_conditions_en',
          [Language.ES]: 'https://talent.develhope.co/terms_and_conditions_esp',
          [Language.TR]: 'https://talent.develhope.co/terms_and_conditions_tr',
          fallback: 'https://talent.develhope.co/terms_and_conditions_en',
        },
      })
      .set(PublicLink.NOTION, {
        fallback: 'https://www.notion.so/School-508e283387d54ef38ee853cce3e921bc',
        language: {
          [Language.EN]: 'https://www.notion.so/School-508e283387d54ef38ee853cce3e921bc',
          fallback: 'https://www.notion.so/School-508e283387d54ef38ee853cce3e921bc',
        },
      })
      .set(PublicLink.CAREER, {
        fallback: 'https://application.develhope.co/it/placement-data',
        country: {
          [Country.IT]: 'https://application.develhope.co/it/placement-data',
          fallback: 'https://application.develhope.co/it/placement-data',
        },
      });
  }

  public getPrivacyPolicyUrl(language: string): string | undefined {
    return this.getPublicUrl(PublicLink.PRIVACY_POLICY, { type: 'language', value: language as Language });
  }

  public getTermsAndConditionsUrl(language: string): string | undefined {
    return this.getPublicUrl(PublicLink.TERMS_AND_CONDITIONS, { type: 'language', value: language as Language });
  }

  public getChatUrl(country: string): string | undefined {
    return this.getPublicUrl(PublicLink.CHAT, { type: 'country', value: country as Country });
  }

  public getFaqUrl(language: string): string | undefined {
    return this.getPublicUrl(PublicLink.FAQ, { type: 'language', value: language as Language });
  }

  public getNotionUrl(language: string): string | undefined {
    return this.getPublicUrl(PublicLink.NOTION, { type: 'language', value: language as Language });
  }

  public getCareerUrl(country: string): string | undefined {
    return this.getPublicUrl(PublicLink.CAREER, { type: 'country', value: country as Country });
  }

  private getPublicUrl<T extends PublicLinkType>(
    link: PublicLink,
    settings: { type: T; value: T extends 'language' ? Language : Country },
  ): string | undefined {
    const internationalLinkObj = this.publicUrls.get(link);
    const { type, value } = settings;

    if (internationalLinkObj) {
      const internationalLink = internationalLinkObj[type];

      if (internationalLink) {
        return (internationalLink[value] || internationalLink[value?.toLowerCase()]) ?? internationalLink['fallback'];
      }

      return internationalLinkObj.fallback;
    }

    return undefined;
  }
}
