import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Nullable } from '@libs/common/types/nullable.type';
import { Language } from '@libs/data/features/user/models/language.model';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';

export type FirebaseAuthPayload = {
  email: string;
  password: string;
};

export type UserCredential = firebase.auth.UserCredential & { user: Nullable<{ ya?: string }> };
export type UserCredentialProfile = {
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
};

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private readonly angularFireAuth: AngularFireAuth) {}

  public signIn(payload: FirebaseAuthPayload): Promise<UserCredential> {
    const { email, password } = payload;

    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public signInWithCustomToken(customToken: string): Promise<UserCredential> {
    return this.angularFireAuth.signInWithCustomToken(customToken);
  }

  public signInWithSocial(provider: firebase.auth.AuthProvider): Promise<UserCredential> {
    return this.angularFireAuth.signInWithPopup(provider);
  }

  public signUp(payload: Omit<FirebaseAuthPayload, 'provider'>): Promise<UserCredential> {
    const { email, password } = payload;

    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  public async sendEmailVerification(): Promise<void> {
    const user = await this.angularFireAuth.currentUser;

    return user?.sendEmailVerification();
  }

  public async updatePassword(password: string): Promise<void> {
    const user = await this.angularFireAuth.currentUser;

    return user?.updatePassword(password);
  }

  public async updateEmail(email: string): Promise<void> {
    const user = await this.angularFireAuth.currentUser;

    return user?.updateEmail(email);
  }

  public sendResetPasswordEmail(email: string): Promise<void> {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  public authState(): Observable<Nullable<firebase.User>> {
    return this.angularFireAuth.authState;
  }

  public async getTokens(): Promise<string | undefined> {
    const user = await this.angularFireAuth.currentUser;

    return user?.getIdToken();
  }

  public setLanguage(language: Language): void {
    firebase.auth().languageCode = language;
  }

  public logout() {
    this.angularFireAuth.signOut();
  }
}
