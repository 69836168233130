import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IConfirmActionPopupTexts } from '@libs/data/models/modal.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ui-kit-confirm-action-popup',
  templateUrl: './confirm-action-popup.component.html',
  styleUrls: ['./confirm-action-popup.component.scss'],
})
export class ConfirmActionPopupComponent {
  @Input() fromParent!: IConfirmActionPopupTexts;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public modal: NgbActiveModal) {}

  @HostListener('document:keydown.escape', ['$event'])
  public closeModal(result: boolean): void {
    if (typeof result !== 'boolean') {
      result = false;
    }
    this.confirm.emit(false);
    this.modal.close(result);
  }
}
