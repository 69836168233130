import { IQuestionQuiz, QuestionQuiz } from '../../../test/models/question/question-quiz.model';
import { ContentType } from '../../../unit/enums/content-type.enum';
import { StudentUnitContentTestStatus } from '../../enums/content/student-unit-content-test-status.enum';
import { IStudentUnitContent, StudentUnitContent } from './student-unit-content.model';

export interface IStudentUnitContentTest extends IStudentUnitContent {
  questions: IQuestionQuiz[];
  tags?: string[];
  version?: number;
  estimateDuration?: number;
  status?: StudentUnitContentTestStatus;
  score?: number;
}

export class StudentUnitContentTest extends StudentUnitContent {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public questions: QuestionQuiz[],
    public tags?: string[],
    public version?: number,
    public estimateDuration?: number,
    public status?: StudentUnitContentTestStatus,
    public score?: number,
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(studentUnitContent: IStudentUnitContentTest): StudentUnitContentTest {
    const questions = studentUnitContent.questions?.map((question) => QuestionQuiz.Build(question)) || [];

    return new this(
      studentUnitContent.id,
      new Date(studentUnitContent.createdAt),
      new Date(studentUnitContent.updatedAt),
      studentUnitContent.title,
      studentUnitContent.description,
      studentUnitContent.type,
      studentUnitContent.blocked,
      questions,
      studentUnitContent.tags,
      studentUnitContent.version,
      studentUnitContent.estimateDuration,
      studentUnitContent.status,
      studentUnitContent.score,
    );
  }
}
