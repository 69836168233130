<div class="d-flex justify-content-between align-items-center h-100">
  <div class="w-100 cursor-pointer mr-2">
    <a (click)="redirectToHome.emit()">
      <img src="assets/images/brand/develhope.svg" alt="Logo Develhope" />
    </a>
  </div>

  <div class="menu-items">
    <ng-container *ngIf="otherLinks?.length">
      <div class="other-links mr-3">
        <div class="link">
          <span routerLink="/dashboard/homepage" [routerLinkActive]="'/dashboard/homepage' ? 'active-link' : ''">
            Home
          </span>
        </div>

        <ng-container *ngFor="let link of otherLinks">
          <div class="link" *authPermission="{ feature: link.feature, actions: link.actions }">
            <span [routerLink]="link?.path" [routerLinkActive]="link.path ? 'active-link' : ''">
              {{ link.name | i18nextEager: { count: 0 } }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <a *ngIf="topbarUrls.notion" [href]="topbarUrls.notion" target="_blank" class="mr-4" rel="noopener">
      {{ "application.Courses.TopBarLabels.Notion" | i18nextEager }}
    </a>

    <a [href]="topbarUrls.chat" target="_blank" class="mr-4" rel="noopener">
      {{ "application.Courses.TopBarLabels.Chat" | i18nextEager }}
    </a>

    <a [href]="topbarUrls.faq" target="_blank" class="mr-4" rel="noopener">
      {{ "application.Courses.TopBarLabels.Faq" | i18nextEager }}
    </a>

    <a *ngIf="topbarUrls.career" [href]="topbarUrls.career" target="_blank" class="mr-4" rel="noopener">
      {{ "application.Courses.TopBarLabels.Career" | i18nextEager }}
    </a>

    <div ngbDropdown id="application-top-bar">
      <button ngbDropdownToggle class="btn btn-dropdown mr-3">
        <ui-kit-user-avatar [currentUser]="currentUser"></ui-kit-user-avatar>
      </button>
      <div ngbDropdownMenu aria-labelledby="contentType" class="dropdown-box">
        <div class="d-flex align-items-center">
          <ui-kit-user-avatar [currentUser]="currentUser"></ui-kit-user-avatar>
          <div class="ml-3">
            <p class="m-0">{{ currentUser.fullName }}</p>
            <p class="mb-0 mt-1">{{ currentUser.email }}</p>
          </div>
        </div>
        <!-- <div class="mt-3">
          <a
            class="btn d-flex align-items-center p-0"
            [ngClass]="{
              'cursor-not-allowed': currentUser.role !== role.STUDENT
            }"
            (click)="redirectToSettings.emit()"
          >
            <img class="dropdown-icon mr-4" src="assets/images/icons/settings.svg" alt="Settings" />
            {{ "Settings" | i18nextEager }}
          </a>
        </div> -->
        <div class="mt-3">
          <button class="btn d-flex align-items-center p-0" (click)="logout.emit()">
            <img class="dropdown-icon mr-4" src="assets/images/icons/log-out-black.svg" alt="Logout" />
            <span class="m-0">{{ "Exit" | i18nextEager }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ui-kit-language-selector
    [isHome]="true"
    [selectedLanguage]="selectedLanguage"
    (setLanguage)="setLanguage.emit($event)"
  ></ui-kit-language-selector>
</div>
