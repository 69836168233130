export const authEnvironment = {
  studentsPlatform: {
    loginType: 'internal',
    url: 'https://piattaforma-digitale-test.web.app/',
  },
  staffPlatform: {
    loginType: 'internal',
    url: 'https://piattaforma-digitale-admin-test.web.app/',
  },
  externalPlatform: {
    signupUrl: '',
    baseUrl: 'https://talent.develhope.co/version-test',
  }
};
