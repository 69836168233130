<img
  class="initials"
  *ngIf="currentUser.personalData.profilePhotoUrl"
  [src]="currentUser.personalData.profilePhotoUrl"
  alt="User profile photo"
  data-test-id="user-profile-picture"
/>
<span
  *ngIf="!currentUser.personalData.profilePhotoUrl"
  class="d-flex justify-content-center align-items-center p-3 initials"
  data-test-id="user-profile-initials"
>
  {{ currentUser.currentUserInitials | uppercase }}
</span>
