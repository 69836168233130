import { IUserSignupPayload, User } from '@libs/data/features/user/models/user.model';
import { IUtm } from '@libs/data/features/user/models/utm.model';
import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/app';

export enum AuthActionTypes {
  LOGOUT = '[Auth] Logout',
  UPDATE_CURRENT_USER = '[Profile] Update current user',
  SIGNIN_WITH_SOCIAL = '[Signin With Social] Signin With Social',
  SIGNIN_WITH_SOCIAL_SUCCESS = '[Signin With Social] Signin With Social Success',
}

export const logout = createAction(AuthActionTypes.LOGOUT);

export const updateCurrentUser = createAction(AuthActionTypes.UPDATE_CURRENT_USER, props<{ user: User }>());

export const signInWithSocial = createAction(
  AuthActionTypes.SIGNIN_WITH_SOCIAL,
  props<{ loginProvider: firebase.auth.AuthProvider; utm?: IUtm }>()
);

export const signInWithSocialSuccess = createAction(
  AuthActionTypes.SIGNIN_WITH_SOCIAL_SUCCESS,
  props<{ user: IUserSignupPayload; isNewUser?: boolean; utm?: IUtm }>()
);
