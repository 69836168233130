import { QuestionType } from '../enums/question-type.enum';
import { IQuestionCheckbox, QuestionCheckbox } from '../models/question/question-checkbox.model';
import { QuestionDate } from '../models/question/question-date.model';
import { IQuestionNumerical, QuestionNumerical } from '../models/question/question-numerical.model';
import { IQuestionOpen, QuestionOpen } from '../models/question/question-open.model';
import { QuestionPhoneNumber } from '../models/question/question-phone-number.model';
import { IQuestionQuiz, QuestionQuiz } from '../models/question/question-quiz.model';
import { IQuestionRank, QuestionRank } from '../models/question/question-rank.model';
import { IQuestion } from '../models/question/question.model';

export class QuestionFactory {
  public static createQuestion(question: IQuestion) {
    const type = question.type;

    switch (type) {
      case QuestionType.CHECKBOX:
        return QuestionCheckbox.Build(question as IQuestionCheckbox);
      case QuestionType.NUMERICAL:
        return QuestionNumerical.Build(question as IQuestionNumerical);
      case QuestionType.OPEN:
        return QuestionOpen.Build(question as IQuestionOpen);
      case QuestionType.QUIZ:
        return QuestionQuiz.Build(question as IQuestionQuiz);
      case QuestionType.RANK:
        return QuestionRank.Build(question as IQuestionRank);
      case QuestionType.DATE:
        return QuestionDate.Build(question as IQuestion);
      case QuestionType.PHONE_NUMBER:
        return QuestionPhoneNumber.Build(question as IQuestion);
      default:
        throw new Error('Invalid content type: ' + type);
    }
  }
}
