import { authEnvironment } from '@libs/auth/environments/environment';

export const parseUrl = (url: string) => url.split(/[?#]/)[0];

export const isStaffPlatform = () => window.location.href.includes(authEnvironment.staffPlatform.url);

export const isStudentsPlatform = () => window.location.href.includes(authEnvironment.studentsPlatform.url);

export const addHttpPrefix = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }

  return url;
};
