import { ContentType } from '../../../unit/enums/content-type.enum';
import { IStudentUnitContent, StudentUnitContent } from './student-unit-content.model';

export interface IStudentUnitContentExample extends IStudentUnitContent {
  url: string;
}

export class StudentUnitContentExample extends StudentUnitContent {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public url: string,
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(studentUnitContent: IStudentUnitContentExample): StudentUnitContentExample {
    return new this(
      studentUnitContent.id,
      new Date(studentUnitContent.createdAt),
      new Date(studentUnitContent.updatedAt),
      studentUnitContent.title,
      studentUnitContent.description,
      studentUnitContent.type,
      studentUnitContent.blocked,
      studentUnitContent.url,
    );
  }
}
