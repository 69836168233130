import { Nullable } from '@libs/common/types/nullable.type';
import { Class, IClass } from '../../class/models/class.model';
import { Permission } from '../../permissions/enums/permission.enum';
import { Gender } from '../../user/enums/gender.enum';
import { Role } from '../../user/enums/role.enum';
import { IEmergencyContact } from '../../user/models/emergency-contact.model.';
import { IUser, User } from '../../user/models/user.model';
import { IUtm } from '../../user/models/utm.model';
import { IStudentStats } from './student-stats.model';
import { ClassStatus } from '../../class/enums/class-status.enum';

export interface IStudentBlockingContent {
  contentId: string;
  lessonId: string;
  title: string;
  unitId: string;
}

export interface IStudentPersonalData {
  age: number;
  attribution: string;
  currentCity: string;
  dateOfBirth: string;
  education: string;
  emergencyContact: Nullable<IEmergencyContact>;
  englishKnowledge: string;
  gender: Gender;
  motivation: string;
  nationality: string;
  occupation: string;
  phoneNumber: string;
  profilePhotoUrl: string;
  programmingKnowledge: string;
}

export interface IStudent extends IUser {
  personalData?: IStudentPersonalData;
  attendedClasses?: string[]; //TODO: da eliminare viene usata in app.component per intercom
  stats?: IStudentStats;
  lastContentBlocked?: IStudentBlockingContent;
  classes?: IClass[];
}

export class Student extends User {
  private constructor(
    public override id = '',
    public override createdAt = '',
    public override updatedAt = '',
    public override bannedAt = '',
    public override verifiedAt = '',
    public override lastAccessAt = '',
    public override permissions: Nullable<Permission[]> = null,
    public override role = Role.STUDENT,
    public override tcAcceptedAt = '',
    public override tcAcceptedVersion = '',
    public override newsletterAccepted = false,
    public override firstname = '',
    public override lastname = '',
    public override country = '',
    public override language = 'it',
    public override email = '',
    public override verified = false,
    public override timezone = '',
    public override utm: Nullable<IUtm> = null,
    public attendedClasses: string[] = [],
    public personalData = {
      age: null,
      attribution: 'none',
      currentCity: '',
      dateOfBirth: '',
      education: 'none',
      emergencyContact: null,
      englishKnowledge: '',
      gender: Gender.Other,
      motivation: '',
      nationality: '',
      occupation: 'none',
      phoneNumber: '',
      profilePhotoUrl: '',
      programmingKnowledge: 'none',
    } as unknown as IStudentPersonalData,
    public override githubUrl = '',
    public stats: IStudentStats = {
      averageVote: 0,
      averageVotePercentage: 0,
      exercises: {
        completed: 0,
        wrong: 0,
        toCorrect: 0,
        total: 0,
      },
      medianVote: 0,
      medianVotePercentage: 0,
      numberOfVideos: 0,
    },
    public lastContentBlocked?: IStudentBlockingContent,
    public classes?: Class[],
  ) {
    super(
      id,
      createdAt,
      updatedAt,
      bannedAt,
      verifiedAt,
      lastAccessAt,
      permissions,
      role,
      tcAcceptedAt,
      tcAcceptedVersion,
      newsletterAccepted,
      firstname,
      lastname,
      country,
      language,
      email,
      verified,
      timezone,
      utm,
      githubUrl,
    );
  }

  public static Build(student: IStudent): Student {
    const classes = (student.classes || []).map((currentClass) => Class.Build(currentClass));

    return new this(
      student.id,
      student.createdAt,
      student.updatedAt,
      student.bannedAt,
      student.verifiedAt,
      student.lastAccessAt,
      student.permissions,
      student.role,
      student.tcAcceptedAt,
      student.tcAcceptedVersion,
      student.newsletterAccepted,
      student.firstname,
      student.lastname,
      student.country,
      student.language?.toLowerCase(),
      student.email,
      student.verified,
      student.timezone,
      student.utm,
      student.attendedClasses,
      student.personalData,
      student.githubUrl,
      student.stats,
      student.lastContentBlocked,
      classes,
    );
  }

  public get currentClass(): Class | undefined {
    return (this.classes || []).find((currentClass) =>
      [ClassStatus.GRADUATED, ClassStatus.IN_PROGRESS].includes(currentClass.status),
    );
  }
}
