import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isStaffPlatform, isStudentsPlatform } from '@libs/common/utils/url';
import { isStudent } from '@libs/common/utils/user';
import { LocalStorageKey } from '@libs/data/enums/local-storage-key.enum';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { authEnvironment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private redirect = localStorage.getItem(LocalStorageKey.REDIRECT_URL);

  constructor(private readonly router: Router) {}

  public getRedirectLink(role: Role): void {
    const isStudentRole = isStudent(role);
    const redirectLink = this.redirect ? this.redirect : '/dashboard/homepage';

    if ((isStudentsPlatform() && isStudentRole) || (isStaffPlatform() && !isStudentRole)) {
      this.router.navigate([redirectLink]);
    } else if ((isStudentsPlatform() && !isStudentRole) || (isStaffPlatform() && isStudentRole)) {
      window.location.href = isStudentRole ? authEnvironment.studentsPlatform.url : authEnvironment.staffPlatform.url;
    }
  }
}
