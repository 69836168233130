import { QuestionType } from '../../enums/question-type.enum';
import { IQuestion, Question } from './question.model';

export interface IScalarQuestion {
  min: { label?: string; value: number };
  max: { label?: string; value: number };
}

export interface IQuestionNumerical extends IQuestion {
  scalar: IScalarQuestion;
  studentAnswer?: number;
}

export class QuestionNumerical extends Question {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override description: string,
    public override question: string,
    public override type: QuestionType,
    public scalar: IScalarQuestion,
    public studentAnswer?: number
  ) {
    super(id, createdAt, updatedAt, description, question, type);
  }

  public static Build(question: IQuestionNumerical): QuestionNumerical {
    return new this(
      question.id,
      new Date(question.createdAt),
      new Date(question.updatedAt),
      question.description,
      question.question,
      question.type,
      question.scalar,
      question.studentAnswer
    );
  }
}
