export const environment = {
  production: false,
  environment: 'develop',
  apiUrl: 'https://piattaforma-digitale-test.ew.r.appspot.com',
  apiKey: '5Ra01+s_kHi)4I9P',
  firebaseConfig: {
    apiKey: 'AIzaSyAjh-kQoeeiYnqKa2yuI9N9hLibyw8DaVw',
    authDomain: 'piattaforma-digitale-dev.firebaseapp.com',
    projectId: 'piattaforma-digitale-test',
    storageBucket: 'piattaforma-digitale-test.appspot.com',
    messagingSenderId: '693313940054',
    appId: '1:693313940054:web:b15750921ed2daeb66e9f7',
  },
  gtmId: 'GTM-NJCNCPJ',
  sentryDsn: 'https://45d1cf6bf43ff0fbfdb3732b14f63d4e@o330695.ingest.sentry.io/4505845680373760'
};
