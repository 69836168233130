<div id="container">
  <div class="content-container h-100">
    <div id="desktop-top-bar">
      <ui-kit-top-bar-desktop
        [currentUser]="currentUser"
        [otherLinks]="otherLinks"
        [topbarUrls]="topbarUrls"
        [selectedLanguage]="selectedLanguage"
        (redirectToHome)="redirectToHome.emit()"
        (redirectToSettings)="redirectToSettings.emit()"
        (setLanguage)="setLanguage.emit($event)"
        (logout)="logout.emit()"
        (goToCareer)="openFeatureDisabledDialog()"
      ></ui-kit-top-bar-desktop>
    </div>

    <div id="mobile-top-bar">
      <div class="d-flex justify-content-between align-items-center h-100">
        <a (click)="redirectToHome.emit()" class="cursor-pointer">
          <img src="assets/images/brand/develhope_mobile_blue.svg" alt="Logo Develhope" />
        </a>
        <img
          class="cursor-pointer"
          src="assets/images/icons/burger-menu.svg"
          alt="burger menu"
          (click)="showMenu = !showMenu"
        />
      </div>
    </div>

    <ui-kit-top-bar-mobile
      [currentUser]="currentUser"
      [otherLinks]="otherLinks"
      [showMenu]="showMenu"
      [topbarUrls]="topbarUrls"
      (handleShowMenu)="showMenu = $event"
      (redirectToHome)="redirectToHome.emit()"
      (redirectToSettings)="redirectToSettings.emit()"
      (setLanguage)="setLanguage.emit($event)"
      (logout)="logout.emit()"
      (goToCareer)="openFeatureDisabledDialog()"
    ></ui-kit-top-bar-mobile>
  </div>
</div>
