export enum UnitAction {
  LIST = 'list',
  CREATE = 'create',
  DETAIL = 'detail',
  EDIT = 'edit',
  ARCHIVE = 'archive',
  HIDE = 'hide',
  EDIT_STUDENTS = 'edit-students',
  EDIT_SUPERVISOR = 'edit-supervisor',
}
