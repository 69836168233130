export enum QuestionType {
  CHECKBOX = 'checkbox',
  NUMERICAL = 'numerical',
  OPEN = 'open',
  OPEN_SHORT = 'open_short',
  QUIZ = 'quiz',
  VIDEO = 'video',
  RANK = 'rank',
  DATE = 'date',
  PHONE_NUMBER = 'phone_number',
}
