import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '@libs/data/features/permissions/enums/permission.enum';
import { Observable } from 'rxjs';
import { PaginatedModel } from '../../../../models/pagination.model';
import { IUpdateUserPayload } from '../../models/user-payload.model';
import { IUser } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class V1UsersRepository {
  constructor(private readonly http: HttpClient) {}

  public getUsers(page = 1, search = '', roles = '', pagelen?: number): Observable<PaginatedModel<IUser>> {
    let params = new HttpParams().set('sort', 1).set('page', page);

    if (search) {
      params = params.set('search', search);
    }

    if (roles) {
      params = params.set('roles', roles);
    }

    if (pagelen) {
      params = params.set('pagelen', pagelen);
    }

    return this.http.get<PaginatedModel<IUser>>(`/v1/users`, { params });
  }

  public getUserById(id: string): Observable<IUser> {
    return this.http.get<IUser>(`/v1/users/${id}`);
  }

  public updateUserById(user: IUpdateUserPayload): Observable<IUser> {
    return this.http.patch<IUser>(`/v1/users/${user.id}`, user);
  }

  public setUserPermissions(userId: string, permissions: Permission[]): Observable<void> {
    return this.http.put<void>(`/v1/users/${userId}/permissions`, { permissions });
  }
}
