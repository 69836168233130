import { ContentType } from '../../enums/content-type.enum';
import { Content, IContent } from './content.model';
import { IUpdateContentPayload } from './update-content-payload.model';

export interface IUpdateContentExamplePayload extends Omit<IUpdateContentPayload, 'type'> {
  type: ContentType.EXAMPLE;
  url: string;
}

export interface IContentExample extends IContent {
  url: string;
}

export class ContentExample extends Content {
  constructor(
    public override id: string,
    public override createdAt: Date,
    public override updatedAt: Date,
    public override title: string,
    public override description: string,
    public override type: ContentType,
    public override blocked: boolean,
    public url: string,
  ) {
    super(id, createdAt, updatedAt, title, description, type, blocked);
  }

  public static Build(content: IContentExample): ContentExample {
    return new ContentExample(
      content.id,
      new Date(content.createdAt),
      new Date(content.updatedAt),
      content.title,
      content.description,
      content.type,
      content.blocked,
      content.url,
    );
  }
}
