<div class="confirm-action-popup">
  <h3 [ngStyle]="{ fontSize: fromParent.titleFontSize + 'px' }">
    {{ fromParent.title | i18nextEager: fromParent.titleParams }}
  </h3>
  <p>{{ fromParent.description || "" | i18nextEager }}</p>
  <div class="confirm-action-popup-footer">
    <button class="btn secondary button-sm" (click)="closeModal(false)" *ngIf="fromParent.back">
      {{ fromParent.back | i18nextEager }}
    </button>
    <button
      class="btn primary button-sm"
      [ngClass]="{ negative: fromParent.isNegativePrimaryBtn }"
      (click)="confirm.emit(true)"
    >
      {{ fromParent.confirm | i18nextEager }}
    </button>
  </div>
</div>
