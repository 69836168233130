import { Nullable } from '@libs/common/types/nullable.type';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { User } from '@libs/data/features/user/models/user.model';
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
  user: Nullable<User | Student | Staff>;
}

export const initialState: AuthState = {
  user: null,
};

export const authFeatureKey = 'auth';

export const authReducer = createReducer(
  initialState,

  on(AuthActions.updateCurrentUser, (state, { user }) => ({
    ...state,
    user,
  }))
);
