import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { ITopBarLink } from '../../models/top-bar-link.model';
import { Role } from '@libs/data/features/user/enums/role.enum';

@Component({
  selector: 'ui-kit-top-bar-desktop',
  templateUrl: './top-bar-desktop.component.html',
  styleUrls: ['./top-bar-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarDesktopComponent {
  @Input() currentUser = {} as Student | Staff;
  @Input() otherLinks: ITopBarLink[] = [];
  @Input() topbarUrls = { chat: '', faq: '', notion: '', career: '' };
  @Input() selectedLanguage = '';

  @Output() redirectToHome = new EventEmitter<void>();
  @Output() redirectToSettings = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();
  @Output() setLanguage = new EventEmitter<string>();

  public role = Role;
}
