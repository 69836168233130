export enum Feature {
  CLASS = 'class',
  CLASS_TEMPLATE = 'class_template',
  UNIT = 'unit',
  UNIT_TYPE = 'unit_type',
  USER = 'user',
  PERMISSION = 'permission',
  CLASS_TYPE = 'class_type',
  CLASS_TAG = 'class_tag',
  SETTINGS = 'settings',
  EXERCISE = 'exercise',
}
