export enum PublicLink {
  FAQ = 'faq',
  CHAT = 'chat',
  NOTION = 'notion',
  CAREER = 'career',
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
}

export type PublicLinkType = 'language' | 'country';

export type InternationalPublicLink = {
  [key in PublicLinkType]?: Record<string, string>;
} & {
  fallback: string;
};
